export const clearLocalStorage = lang => {
  localStorage.removeItem(`routes-${lang}`);
  localStorage.removeItem(`routesData-${lang}`);
};

export const getLocalStorageValue = key => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

export const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
