import React from 'react';
import './styles.scss';

const ActionButton = ({
  legend,
  buttonText,
  handleClick,
  className = '',
  disabled = false
}) => {
  return (
    <button
      className={'action-button ' + className}
      onClick={handleClick}
      disabled={disabled}
      title={disabled ? 'Please select all the fields' : ''}
    >
      {legend ? (
        <img src={legend} width="48px" height="48px" alt="" />
      ) : (
        buttonText
      )}
    </button>
  );
};

export default ActionButton;
