import { useCallback, useEffect, memo } from 'react';
import './styles.scss';
import { useStorePersist } from 'store/context';
import { isMobile } from 'helpers/Mobile';

const TooltipDialog = memo(() => {
  const [
    { tooltipIndex, toolkitCompleted },
    dispatchPersist
  ] = useStorePersist();

  const dispatchToolkitIndex = useCallback(
    index => {
      dispatchPersist({
        type: 'setTooltipIndex',
        payload: index
      });
    },
    [dispatchPersist]
  );

  const firstStep = useCallback(() => {
    if (!isMobile) {
      //Selecciono el elemento a mostrar en este paso
      const timelineContainer = document.getElementById('timeline-container');

      //Agrego la clase para el highlight
      if (timelineContainer) {
        timelineContainer.classList.add('toolkit-highlighted-component');
      }

      //Clickeo en la card 0 en caso que no este ahi
      const cardZero = document.getElementById('route-0');
      cardZero && cardZero.click();
    } else {
      const recCarousel = document.querySelector('.rec-carousel');
      const rightArrow = document.querySelector('.rec-arrow-right');
      const leftArrow = document.querySelector('.rec-arrow-left');

      const waitForArrows = async () => {
        recCarousel &&
          recCarousel.classList.remove('toolkit-highlighted-component');
        await new Promise(resolve => setTimeout(resolve, 50));
        leftArrow.classList.add('toolkit-highlighted-component');
        rightArrow.classList.add('toolkit-highlighted-component');
      };

      if (leftArrow && rightArrow) {
        waitForArrows();
      }
    }
  }, []);

  const secondStep = useCallback(() => {
    if (!isMobile) {
      //Selecciono el elemento a mostrar en este paso
      const cardOne = document.getElementById('route-1');
      cardOne && cardOne.click();

      // Remuevo el highlight del elemento anterior
      const timelineContainer = document.getElementById('timeline-container');
      if (timelineContainer) {
        timelineContainer.classList.remove('toolkit-highlighted-component');
      }

      const cardContainerDesktop = document.getElementById(
        'card-container-desktop'
      );

      const cardContainerDesktopCarousel = document.querySelector(
        '.rec-carousel-wrapper'
      );

      const methodologiesButtonOpaque = document.querySelector(
        '#card-container-desktop .route-body-certifications'
      );

      const cardRouteBodyContent = document.querySelector(
        '.route-body-content'
      );
      const cardRouteCarousel = document.querySelector('.rec-carousel-wrapper');

      if (
        cardRouteBodyContent &&
        cardRouteBodyContent.classList.contains('opaque')
      ) {
        cardRouteBodyContent.classList.remove('opaque');
      }
      if (cardRouteCarousel && cardRouteCarousel.classList.contains('opaque')) {
        cardRouteCarousel.classList.remove('opaque');
      }

      if (methodologiesButtonOpaque) {
        methodologiesButtonOpaque.classList.add('opaque');
      }
      if (cardContainerDesktopCarousel) {
        cardContainerDesktop.classList.remove('opaque');
      }

      if (cardContainerDesktop) {
        cardContainerDesktop.classList.add('toolkit-highlighted-component');
      }
    } else {
      const closeDetail = document.getElementById('close-card-detail');
      const recCarousel = document.querySelector('.rec-carousel');
      const leftArrow = document.querySelector('.rec-arrow-left');
      const rightArrow = document.querySelector('.rec-arrow-right');

      closeDetail && closeDetail.click();

      if (leftArrow && rightArrow) {
        leftArrow.classList.remove('toolkit-highlighted-component');
        rightArrow.classList.remove('toolkit-highlighted-component');
      }
      recCarousel && recCarousel.classList.add('toolkit-highlighted-component');
    }
  }, []);

  const thirdStep = useCallback(() => {
    if (!isMobile) {
      const cardRouteBodyContent = document.querySelector(
        '.route-body-content'
      );
      const cardRouteCarousel = document.querySelector('.rec-carousel-wrapper');

      const methodologiesButtonOpaque = document.querySelector(
        '#card-container-desktop .route-body-certifications'
      );

      if (cardRouteBodyContent) {
        cardRouteBodyContent.classList.add('opaque');
      }
      if (cardRouteCarousel) {
        cardRouteCarousel.classList.add('opaque');
      }

      if (methodologiesButtonOpaque) {
        methodologiesButtonOpaque.classList.remove('opaque');
      }
    } else {
      const recCarousel = document.querySelector('.rec-carousel');
      const seeMore = document.getElementById('card-with-image-line-1');

      recCarousel &&
        recCarousel.classList.remove('toolkit-highlighted-component');

      const waitForSeeMoreAndClick = async () => {
        seeMore.click();

        await new Promise(resolve => setTimeout(resolve, 100));
        let methodologyButton = document.getElementById(
          'methodCollectionBatch'
        );
        await new Promise(resolve => setTimeout(resolve, 100));

        if (methodologyButton) {
          methodologyButton.scrollIntoView({
            behavior: 'smooth'
          });
          methodologyButton.classList.add('toolkit-highlighted-component');
        }
      };

      if (seeMore) {
        waitForSeeMoreAndClick();
      }
    }
  }, []);

  useEffect(() => {
    dispatchToolkitIndex(1);
  }, [dispatchToolkitIndex]);

  useEffect(() => {
    switch (tooltipIndex) {
      case 1:
        firstStep();
        break;
      case 2:
        secondStep();
        break;
      case 3:
        thirdStep();
        break;
      default:
        break;
    }
  }, [tooltipIndex, firstStep, secondStep, thirdStep]);

  useEffect(() => {
    if (toolkitCompleted === true) {
      document.querySelectorAll('.opaque').forEach(item => {
        item.classList.remove('opaque');
      });
    }
  }, [toolkitCompleted]);

  return null;
});

function closeTooltip(dispatchPersist) {
  if (isMobile) {
    // FUERZO EL INDEX A 1 PARA QUE NO SE ABRA EL MODAL DEL SEE MORE EN MOBILE AL VOVLER A INICIAR EL TOOLTIP
    dispatchPersist({
      type: 'setTooltipIndex',
      payload: 1
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  const opaques = document.querySelectorAll('.opaque');
  if (opaques) {
    opaques.forEach(item => {
      item.classList.remove('opaque');
    });
  }

  const dispatchTooltipCompleted = status => {
    dispatchPersist({
      type: 'setTooltipCompleted',
      payload: status
    });
  };
  dispatchTooltipCompleted(true);

  const cardZero = document.getElementById('route-0');
  cardZero && cardZero.click();

  const closeDetail = document.getElementById('close-card-detail');
  closeDetail && closeDetail.click();
}

export { TooltipDialog, closeTooltip };
