export const generateProductFields = data => {
  return {
    posType: data.points_of_sale_screen_type.type,
    url: data.fields['Lateral Image'][0].thumbnails.large.url,
    name: data.fields['Name'],
    name_trace: data.fields['Name (Trace System)'],
    name_trace_es: data.fields['Nombre (Trace)'],
    co2: data.fields['C02 Production Emission (kg)'],
    co2_trace: data.fields['CO2 Tracer Emission'],
    bioequivalence: data.fields['Bioequivalence'],
    bioequivalence_es: data.fields['Bioequivalencia'],
    boot_material: data.fields['Boot Material'],
    boot_material_es: data.fields['Material Botín'],
    base_material: data.fields['Base Material'],
    base_material_es: data.fields['Material Base'],
    recycled_materials: data.fields['Card 0 - Recycled Materials'],
    recycled_materials_es: data.fields['Card 0 - Material Reciclado']
  };
};

