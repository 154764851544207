import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { ProductService } from 'services/ProductService';
import HomeTemplate from '../../components/templates/Home';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';
import Config from '../../config/config.js';
import { RouteService } from 'services/RouteService';



const HomeView = props => {
  const { dealWithError } = useRequest();
  const [code, setCode] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [error, setError] = useState('');
  const [{ }, dispatchPersist] = useStorePersist();
  const { t, i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    localStorage.removeItem('routesData-en');
    localStorage.removeItem('routesData-es');
    localStorage.removeItem('routes-en');
    localStorage.removeItem('routes-es');
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('qr');
    if (queryParam) {
      if (Config.all_viewports.home.form_uppercase) {
        setCode(queryParam.toUpperCase());
      } else {
        setCode(queryParam);
      }
    }
  }, [location]);

  const handleSearch = e => {
    setError('');
    if (!code || code.trim() == '') {
      setError([t('home.error')]);
      return;
    }
    if ((e.key === 'Enter' && code) || (e.type === 'click' && code)) {
      let codeSplitted = code.split('-');
      if (codeSplitted.length !== 2) {
        ProductService.search(0, 0, code)
          .then(data => {
            if (data.detail === 'No encontrado.') {
              setError([t('home.error')]);
              return;
            }

            let lens = Config.product_fields(data, Config.product_theme);

            dispatchPersist({
              type: 'setLens',
              payload: lens
            });

            //agregar route-check para codigo sin sku-batch (Lo tiene que desarrollar Guille)

            if (data.show_points_of_sale_screen) {
              history.push({
                pathname: '/search'
              });
            } else {
              history.push({
                pathname: '/map'
              });
            }
          })
          .catch(error => {
            setError([t('home.error')]);
            dealWithError(error, 'Error from QrScanner');
          });

        return;
      }
      ProductService.search(codeSplitted[0].trim(), codeSplitted[1].trim())
        .then(data => {

          let lens = Config.product_fields(data, Config.product_theme);

          dispatchPersist({
            type: 'setLens',
            payload: lens
          });

          RouteService.check(
            codeSplitted[0].trim(),
            codeSplitted[1].trim(),
            `${lens.material_one},${lens.material_two}`,
          )
            .then(response => {
              if (response.blocked) {
                setShowModal(true);
                const message = language ? response[`message_${language}`] : response.message;
                setModalMessage(message);
              } else {
                if (data.show_points_of_sale_screen) {
                  history.push({
                    pathname: '/search'
                  });
                } else {
                  history.push({
                    pathname: '/map'
                  });
                }
              }
            }).catch(error => {
              console.log('error: ', error);
              setError([t('home.error')]);
              dealWithError(error, 'Error from handleSearch @ HomeView in route_check service');
            });

        })
        .catch(error => {
          console.log('error: ', error);
          setError([t('home.error')]);
          dealWithError(error, 'Error from handleSearch @ HomeView');
        });
    }
  };

  const handleClick = e => {
    e.preventDefault();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <HomeTemplate
      code={code}
      setCode={setCode}
      handleSearch={handleSearch}
      handleClick={handleClick}
      error={error}
      setError={setError}
      handleClose={handleClose}
      showModal={showModal}
      modalMessage={modalMessage}
    />
  );
};

export default withTranslation()(HomeView);
