import React from 'react';
import { Marker } from 'react-map-gl';
import LocationPin from '../LocationPin';
import { isMobile } from 'helpers/Mobile';
import variables from '../../../config/variables.scss';


const CustomMarker = ({ latitude, longitude, isActive, key, cardIndex }) => (
    <Marker
        latitude={latitude}
        longitude={longitude}
        key={key}
        style={{
            zIndex: isActive ? 2 : 1,
            transition: 'z-index 2s ease-out',
        }}
    >
        <LocationPin
            styles={{
                background: isActive && variables.primary,
                borderColor: isActive && variables.primary,
                transform: isMobile && isActive && 'scale(1.25)',
            }}
            cardIndex={cardIndex}
            numberStyles={{
                color: isActive ? variables.map_active_pin_number_color : '#FFF',
            }}
        />
    </Marker>
);

export default React.memo(CustomMarker);
