import { Assets } from "./assets";
import { productFields } from "../helpers/productFields.js";
import { Translations } from "./translations";

const Config = {
    "product_theme": window.__RUNTIME_CONFIG__.REACT_APP_PRODUCT_THEME,
    "all_viewports": {
        "home": {
            "qr_button": false,
            "form_uppercase": true,
        },
        "map": {
            mapBgGradient: { background: 'linear-gradient(#0A273A, #000000 35%)' },
            colorMap: {
                version: 8,
                name: 'Mapbox Streets tileset v8',
                sources: {
                    'country-boundaries': {
                        type: 'vector',
                        url: 'mapbox://mapbox.country-boundaries-v1'
                    }
                },
                layers: [
                    {
                        id: 'background',
                        type: 'background',
                        paint: {
                            'background-color': 'transparent'
                        },
                        interactive: false
                    },
                    {
                        id: 'undisputed country boundary fill',
                        source: 'country-boundaries',
                        'source-layer': 'country_boundaries',
                        type: 'fill',
                        paint: {
                            'fill-color': 'transparent',
                            'fill-outline-color': '#F2F2F2'
                        }
                    },
                    {
                        id: 'disputed area boundary fill',
                        source: 'country-boundaries',
                        'source-layer': 'country_boundaries',
                        type: 'fill',
                        paint: {
                            'fill-color': 'transparent',
                            'fill-outline-color': '#F2F2F2'
                        }
                    }
                ]
            },
            carbonFootprint: {
                isDeliveryActive: false
            },
            seeMoreCard: {
                url: "http://www.karunworld.com"
            },
            footerLine: {
                isActive: true,
                colors: {
                    firstColor: '#711F0E',
                    secondColor: '#344A5B',
                    thirdColor: '#336E65',
                    fourthColor: '#BE8039',
                }
            }
        }
    },
    "dkt": {},
    "mob": {},
    "assets": Assets,
    "translations": Translations,
    "product_fields": productFields,
};


export default Config;