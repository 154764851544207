import React from 'react';
import Sidebar from 'components/molecules/Sidebar';
import { isMobile } from 'helpers/Mobile';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import LanguageSwitch from '../../atoms/LanguageSwitch';
import KarunLogo from 'assets/images/karun-logo.png';
import LogoTrace from 'assets/images/logo-kts.png';
import { Container, Navbar, CloseButton } from 'react-bootstrap';

import Config from '../../../config/config.js';


const video = Config.assets[Config.product_theme].video_bg_mob;

const MainLayout = ({
  children,
  showMenu = false,
  handleClose,
  className,
  lens,
  isHome,
  isSearch,
  isTrace = false
}) => {
  return (
    <div className={'main-layout-container ' + (className || '')}>
      {isMobile ? (
        <Sidebar
          showMenu={showMenu}
          handleClose={handleClose}
          lens={lens}
          isTrace={isTrace}
          isHome={isHome}
          isSearch={isSearch}
        />
      ) : (
        <>
          {!isMobile && (
            <div className="header-home-desktop">
              <Navbar expand={false} variant="dark">
                <Container fluid>
                  <LanguageSwitch />
                  {isTrace && <CloseButton variant="white" onClick={handleClose} className='dkt-close-btn' />}
                  <Navbar.Brand href="https://www.karunworld.com/jointheplanet" className="navbar-brand" target='_blank' >
                    <img
                      src={KarunLogo}
                      // src={isHome || isSearch ? KarunLogo : LogoTrace}
                      alt="Logo"
                    />
                  </Navbar.Brand>
                </Container>
              </Navbar>
            </div>
          )}
        </>
      )}
      {isMobile && (isHome || isSearch) && (
        <div className="video-background-container">
          <video autoPlay loop muted playsInline >
            <source src={video} />
          </video>
        </div>
      )}

      {children}
    </div>
  );
};

export default MainLayout;
