const Environment = {
    production: (window.__RUNTIME_CONFIG__.REACT_APP_PRODUCTION === 'true'),
    // production: false,
    api: window.__RUNTIME_CONFIG__.REACT_APP_API,
    mapbox_api_key: window.__RUNTIME_CONFIG__.REACT_APP_MAPBOX_ACCESS_TOKEN,
    // api: 'http://192.168.106.248:8000/',
    statics: window.__RUNTIME_CONFIG__.REACT_APP_STATICS
}

export default Environment;


