import Environment from "../environment";
import Api from './ApiService';

export const PointsOfSaleService = {//change name of service
    list: (batch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await Api.fetchNoToken(`${Environment.api}points-of-sale?id_code=${await batch}`, 'GET');
                resolve(data);
            } catch (err) {
                reject(err);
            }
        });
    },

    T2: {
        continent: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}pos-continent`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },

        country: (batch, idContinent = null) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}pos-country?id_code=${batch}${idContinent ? "&p=" + idContinent : ""}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },
        state: (idCountry, batch) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}pos-state?p=${idCountry}&id_code=${batch}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },
        city: (idState, batch) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}pos-city?p=${idState}&id_code=${batch}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },
        pos: (idCity, batch) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}pos-on-city-list?p=${idCity}&id_code=${batch}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        }
    },

    T3: {
        country: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}deliverycountry`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },
        state: (idCountry) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}deliverystate?p=${idCountry}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        },
        city: (idState) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await Api.fetchNoToken(`${Environment.api}deliverycity?p=${idState}`, 'GET');
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            });
        }

    }
};
