import React from 'react'
import Config from '../../../config/config';
import './styles.scss';

const { firstColor, secondColor, thirdColor, fourthColor } = Config.all_viewports.map.footerLine.colors;

const MapFooterLine = () => {
  return (
    <footer>
      <div className='first-line' style={{ background: firstColor }}></div>
      <div className='second-line' style={{ background: secondColor }}></div>
      <div className='third-line' style={{ background: thirdColor }}></div>
      <div className='fourth-line' style={{ background: fourthColor }}></div>
    </footer>
  )
}

export default MapFooterLine