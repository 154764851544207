import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CloseButton from 'react-bootstrap/CloseButton';
import DetailBody from '../DetailBody';
import DetailBodyDesktop from '../DetailBodyDesktop';
import { isMobile } from 'helpers/Mobile';
import { TooltipDialog } from 'components/atoms/TooltipDialog/TooltipDialog';
import TooltipTopBar from 'components/atoms/TooltipDialog/TooltipTopBar';
import { useStorePersist } from 'store/context';
import './styles.scss';

const Detail = props => {
  const handleClose = () => setShow(false);
  const { show, setShow } = props;
  const [{ tooltipCompleted }] = useStorePersist();

  return (
    <div className="detail-container">
      {isMobile && !tooltipCompleted && (
        <>
          <TooltipDialog />
          <TooltipTopBar />
        </>
      )}

      <Offcanvas
        id="offcanvasDetail"
        show={show}
        onHide={handleClose}
        placement="bottom"
        name="bottom"
      >
        <Offcanvas.Body id="offcanvasBody">
          <CloseButton
            onClick={handleClose}
            id="close-card-detail"
          />
          <DetailBody {...props} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Detail;
