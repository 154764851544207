import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import './styles.scss';
import Config from 'config/config';

const Co2Status = React.memo(({ lens, deliveryCarbonFootprint }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { isDeliveryActive } = Config.all_viewports.map.carbonFootprint;

  return useMemo(
    () => (
      <div id="co2-container" className="co2-container">
        <div className="co2-inner-container">
          <h4>
            {t('mapAllCards.carbonFootprint')}

          </h4>

          {/* Huella de Carbono Produccion */}
          {lens && lens.co2 && (
            <>
              <div id="co2-info">
                <div className="carbon-data-container">
                  <h5 className="huella-carbon-titulos">
                    {t('mapAllCards.carbonFootprintProduction')}
                  </h5>
                </div>
                <div className="detail-box detail-box-kg">
                  <h3>{lens.co2} kgC02e</h3>
                </div>
              </div>
            </>
          )}
          {/* Huella de Carbono Tracer*/}
          {lens && lens.co2 && (
            <>
              <div id="co2-info3">
                <div className="carbon-data-container">
                  <h5 className="huella-carbon-titulos">
                    {t('mapAllCards.carbonFootprintTracer')}
                    &nbsp;
                    <span onClick={handleShowModal} className='info-carbon-footprint'> i</span>
                  </h5>
                </div>
                <div className="detail-box detail-box-kg">
                  <h3>{lens.co2_trace} 1.55 kgC02e</h3>
                </div>
              </div>
            </>
          )}
          {/* Huella de Carbono Delivery*/}
          {isDeliveryActive && lens && lens.co2 && lens.posType === 'T3' && (
            <>
              <div id="co2-info3">
                <div className="carbon-data-container">
                  <h5 className="huella-carbon-titulos">
                    {t('mapAllCards.carbonFootprintDelivery')}
                  </h5>
                </div>
                <div className="detail-box detail-box-kg">
                  <h3>{deliveryCarbonFootprint} kg CO2e</h3>
                </div>
              </div>
            </>
          )}
          <p className="huella-carbon-peso">
            {language
              ? lens[`bioequivalence_${language}`]
              : lens.bioequivalence}
          </p>
        </div>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="desktop-info-footprint-modal justify-content-center"
          centered
        >
          <Modal.Header closeButton>
            <h5>{t('mapZeroCard.tracerCarbonFootprint')}</h5>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('mapZeroCard.carbonFootprintInfo')}
            </p>

          </Modal.Body>
        </Modal>
      </div>
    ),
    [lens, language, showModal]
  );
});

export default Co2Status;
