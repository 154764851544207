import React from 'react';
import { useStorePersist } from 'store/context';
import { closeTooltip } from './TooltipDialog';
import { useTranslation } from 'react-i18next';

export const Tooltip = () => {
  const { t } = useTranslation();

  //Consulto el estado del tooltipIndex
  const [{ tooltipIndex }, dispatchPersist] = useStorePersist();

  // Diccionario de pasos (refs)
  const steps = [
    {
      number: 1,
      message: t('tooltip.tooltip1')
    },
    {
      number: 2,
      message: t('tooltip.tooltip2')
    },
    {
      number: 3,
      message: t('tooltip.tooltip3')
    }
  ];

  let currentStep = {
    number: 1,
    message: t('tooltip.tooltip1')
  };

  steps.forEach(item => {
    if (item.number === tooltipIndex) {
      currentStep = item;
    }
  });

  const handleCloseClick = () => {
    closeTooltip(dispatchPersist);
  };

  const handleNextClick = () => {
    dispatchPersist({
      type: 'setTooltipIndex',
      payload: tooltipIndex + 1
    });
  };

  const handlePrevClick = () => {
    dispatchPersist({
      type: 'setTooltipIndex',
      payload: tooltipIndex - 1
    });
  };

  return (
    <div id={`toolkit-tip-${currentStep.number}`} className="toolkit-tip">
      <button className="tooltip-close" onClick={handleCloseClick}>
        X
      </button>
      <p>{currentStep.message}</p>
      <div className="tooltip-nav">
        {tooltipIndex !== 1 && (
          <button className="prevTooltip" onClick={handlePrevClick}>
            {'<<'}
            {t('tooltip.prev')}
          </button>
        )}
        {tooltipIndex !== 3 && (
          <button className="nextTooltip" onClick={handleNextClick}>
            {t('tooltip.next')} {'>>'}{' '}
          </button>
        )}

        {tooltipIndex === 3 && (
          <button className="nextTooltip" onClick={handleCloseClick}>
            {t('tooltip.start')} {'>>'}{' '}
          </button>
        )}
      </div>
    </div>
  );
};
