import i18n from 'i18n';

const Api = {
  fetchNoToken: (url, method = 'GET', data = null) => {
    const info = Object.assign(
      {},
      {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language
        }
      },
      data && method !== 'GET' ? { body: JSON.stringify(data) } : null
    );

    url = data && method === 'GET' ? Api.getUrlWithParams(url, data) : url;

    return fetch(url, info)
      .then(Api.handleErrors)
      .then(resp => {
        if (resp.status === 204) {
          return null;
        }
        return resp.json();
      });
  }
};

export default Api;
