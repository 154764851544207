import React from 'react';
import './styles.scss';
import FormSelectB from 'react-bootstrap/FormSelect';

const FormSelect = ({ label, options, ariaLabel, placeHolder, onChange }) => {
  return (
    <div className={'select'}>
      <h6 className="select-label">{label}</h6>

      <FormSelectB aria-label={ariaLabel} onChange={onChange} defaultValue="">
        <option value="" disabled className={'select-option'}>
          {placeHolder}
        </option>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            className={'select-option'}
            disabled={option.disabled}
          >
            {option.name}
          </option>
        ))}
      </FormSelectB>
    </div>
  );
};

export default React.memo(FormSelect)
