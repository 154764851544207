import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import useRequest from 'hooks/useRequest';
import { isMobile } from 'helpers/Mobile';
import { RouteService } from 'services/RouteService';
import MapTemplate from '../../components/templates/Map';
import { useStorePersist } from 'store/context';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import {
  getLocalStorageValue,
  setLocalStorageValue
} from 'helpers/LocalStorageActions';

const MapView = props => {
  // Local storage persistance preventing innecesay requests
  const { dealWithError } = useRequest();
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const language = i18n.language;
  const handleClose = () => history.push({ pathname: '/' });
  const [
    { lens, country, city, countryStr, cityStr, posId }, dispatchPersist
  ] = useStorePersist();
  const query = useQuery();
  const [initialViewport, setInitialViewport] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [currentMaterial, setCurrentMaterial] = useState();
  const [blockchain, setBlockchain] = useState([]);
  const [deliveryCF, setDeliveryCF] = useState(0);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const fetchRoutes = async () => {
    try {
      //Tries to fetch if no language is found on localStorage
      if (!getLocalStorageValue(`routes-${language}`)) {
        // Check for avoiding entering the map without a route
        if (lens === undefined || lens === null) {
          history.push({ pathname: '/', search: '?error=no_route' });
        }

        const filterByMaterial = () => {
          if (lens.posType === 'T2' || lens.posType === 'T3') {

           // const materialOne = lens.material_one === "ECONYL® Regenerated Nylon" ? "Econyl Blend" : lens.material_one;
           // Econyl names have been unified in the airtable and this replacement no longer makes sense

           const materialOne = lens.material_one

            if(lens.material_one === lens.material_two) {
              return materialOne;
            }

            if (lens.material_two) {
              return `${materialOne},${lens.material_two}`;
            }

            if (lens.material_one) {
              return materialOne;
            }
          }
          return null;
        };

        // Fetching routes
        const data = await RouteService.routes(
          lens.batch,
          filterByMaterial(),
          country,
          city,
          i18n.language ? i18n.language.substring(0, 2) : 'es',
          posId,
          lens.sku
        );

        if (data.error) {
          setShowErrorAlert(true);
        }

        dispatchPersist({
          type: 'setShortRoute',
          payload: data.show_short_route
        });


        // multiRoutesCoords = Define the route with coordinates including all the multiple points at each step; this array is used in MapTemplate to build routes, markers, etc.
        const multiRoutesCoords = data.routes.map(stepArray =>
          stepArray.map(step => ({
            material: step.material,
            order: step.order,
            origin: step.origin.geolocation.map(point => ({
              type: step.origin.type,
              latitude: parseFloat(point.split(',')[0]),
              longitude: parseFloat(point.split(',')[1])
            })),
            destiny: step.destiny.geolocation.map(point => ({
              type: step.destiny.type,
              latitude: parseFloat(point.split(',')[0]),
              longitude: parseFloat(point.split(',')[1])
            }))
          }))
        );

        // cardsList = Define the data for each card according to each step, which is consumed in MapTemplate for both desktop (dkt) and mobile cards.
        const cardsList = data.routes.map(
          stepArray => stepArray[0].origin.card_route
        );
        if (!data.show_short_route) {
          cardsList.push(
            data.routes[data.routes.length - 1][
              data.routes[data.routes.length - 1].length - 1
            ].destiny.card_route
          );
        }

        let deliveryCarbonFootprint = data.routes[data.routes.length - 1][
          data.routes[data.routes.length - 1].length - 1
        ].destiny.carbon_footprint;

        setDeliveryCF(deliveryCarbonFootprint);

        let materials = [lens.frame_material, lens.temple_material];
        materials = [...new Set(materials)];

        let blockchain = data.blockchain;

        setInitialViewport({
          width: '100vw',
          height: isMobile ? '60vh' : '100vh',
          latitude: multiRoutesCoords[0][0].origin[0].latitude,
          longitude: multiRoutesCoords[0][0].origin[0].longitude,
          zoom: 2
        });
        setRoutes(multiRoutesCoords);
        setLocalStorageValue(`routes-${language}`, multiRoutesCoords);
        setLocalStorageValue(`routesData-${language}`, data);
        setBlockchain(blockchain);
        setCardsData(cardsList);
        setCurrentMaterial(query.get('material'));
      } else {
        // If data is found on localStorage, it fetches from there
        setRoutes(getLocalStorageValue(`routes-${language}`));
        const data = getLocalStorageValue(`routesData-${language}`);

        const multiRoutesCoords = data.routes.map(stepArray =>
          stepArray.map(step => ({
            material: step.material,
            order: step.order,
            origin: step.origin.geolocation.map(point => ({
              type: step.origin.type,
              latitude: parseFloat(point.split(',')[0]),
              longitude: parseFloat(point.split(',')[1])
            })),
            destiny: step.destiny.geolocation.map(point => ({
              type: step.destiny.type,
              latitude: parseFloat(point.split(',')[0]),
              longitude: parseFloat(point.split(',')[1])
            }))
          }))
        );
        const cardsList = data.routes.map(
          stepArray => stepArray[0].origin.card_route
        );
        if (!data.show_short_route) {
          cardsList.push(
            data.routes[data.routes.length - 1][
              data.routes[data.routes.length - 1].length - 1
            ].destiny.card_route
          );
        }


        let deliveryCarbonFootprint = data.routes[data.routes.length - 1][
          data.routes[data.routes.length - 1].length - 1
        ].destiny.carbon_footprint;

        setDeliveryCF(deliveryCarbonFootprint);



        let materials = [lens.frame_material, lens.temple_material];
        materials = [...new Set(materials)];

        let blockchain = data.blockchain;
        setInitialViewport({
          width: '100vw',
          height: isMobile ? '60vh' : '80vh',
          latitude: multiRoutesCoords[0][0].origin[0].latitude,
          longitude: multiRoutesCoords[0][0].origin[0].longitude,
          zoom: 2
        });
        setRoutes(multiRoutesCoords);
        setLocalStorageValue(`routes-${language}`, multiRoutesCoords);
        setLocalStorageValue(`routesData-${language}`, data);
        setBlockchain(blockchain);
        setCardsData(cardsList);
        setCurrentMaterial(query.get('material'));
      }
    } catch (error) {
      dealWithError(error, 'Error in TRY @ Mapview');
    }
  };

  useEffect(() => {
    fetchRoutes();
  }, [language]);

  const handleBackToSearch = () => {
    history.push({
      pathname: '/search'
    });
  };

  return (
    <>
      {showErrorAlert ?
        <Modal
          centered
          show={showErrorAlert}
          onHide={handleBackToSearch}
          closeVariant='white'
          className='routes-service-error-modal'
        >
          <Modal.Header
            closeButton
            closeVariant='white'
          />
          <Modal.Body>
            <p>{t('search.routeNotFound')}</p>
          </Modal.Body>
        </Modal>
        :
        <>
          {initialViewport && routes && (
            <MapTemplate
              initialViewport={initialViewport}
              markers={routes}
              cardsData={cardsData}
              currentMaterial={currentMaterial}
              handleClose={handleClose}
              lens={lens}
              country={countryStr}
              city={cityStr}
              blockchain={blockchain}
              deliveryCarbonFootprint={deliveryCF}
            />
          )}
        </>
      }
    </>
  );
};

export default withTranslation()(MapView);
