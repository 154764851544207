import React, { useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { isMobile } from 'helpers/Mobile';
import { clearLocalStorage } from 'helpers/LocalStorageActions';
import { useStorePersist } from 'store/context';
import QrScanner from 'components/molecules/QrScanner';
import MainLayout from 'components/organisms/MainLayout';
import Form from 'react-bootstrap/Form';
import ActionButton from 'components/atoms/ActionButton';
import QrExplanation from 'components/atoms/QrExplanation';
import QrIcon from 'assets/images/qr-icon.png';
import Config from '../../../config/config.js';
import CustomModal from 'components/molecules/CustomModal/index.js';

import './styles.scss';

const video = Config.assets[Config.product_theme].video_bg_dkt;
const step1 = Config.assets[Config.product_theme].home.modal.step1;
const step2 = Config.assets[Config.product_theme].home.modal.step2;
const step3 = Config.assets[Config.product_theme].home.modal.step3;
const top_dkt = Config.assets[Config.product_theme].home.logos.top_dkt;
const top_mobile = Config.assets[Config.product_theme].home.logos.top_mobile;
const bottomLogo = Config.assets[Config.product_theme].home.logos.bottom;

const Home = React.memo(({ code, setCode, handleSearch, error, setError, handleClose, showModal, modalMessage }) => {
  //Clearing local storage cache
  const [{ locale }] = useStorePersist();
  clearLocalStorage(locale);
  const [openInstructions, setOpenInstructions] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const { t } = useTranslation();
  const stepsRef = useRef(null);

  const handleCode = e => {
    e.preventDefault();
    let code = e.target.value;
    if (Config.all_viewports.home.form_uppercase) {
      code = code.toUpperCase();
    }
    setCode(code);
  };

  const handleStartScan = () => {
    setIsQrOpen(!isQrOpen);
    setStartScan(!startScan);
  };

  return (
    <MainLayout showMenu={false} isHome={true}>
      <div
        className="home-container"
        style={{ zIndex: openInstructions ? 1001 : 999 }}
      >
        {!isMobile && (
          <div className="video-background-container-dkt">
            <video autoPlay loop muted playsInline>
              <source src={video} />
            </video>
          </div>
        )}

        <div className="home-search">
          {isMobile ? (
            <img className="logo-mobile" src={top_mobile} width='200px' alt='logo' />
          ) : (
            <img className="logo-dkt" src={top_dkt} width='200px' alt='logo' />
          )}
          <p className="home-subtitle">{t('home.subtitle')}</p>
          <h1 className="home-title">{t('home.title')}</h1>
          <div
            className={
              'input-text-container input-search ' +
              (error !== '' ? 'error' : '')
            }
          >
            <Form.Group className="form-container">
              <Form.Label>{t('home.code')}</Form.Label>
              <div className="input-and-qr-container">
                <div className="input-qr-inner-container">
                  <div className="input-button-container">
                    <Form.Control
                      placeholder={t('home.placeholder')}
                      title={t('home.searchbartip')}
                      value={code || ''}
                      type={'text'}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          handleSearch(event);
                        }
                      }}
                      onChange={event => {
                        handleCode(event);
                      }}
                    />
                    <button
                      className={
                        'button-text-search' + (error != '' ? ' error' : '')
                      }
                      onClick={event => {
                        handleSearch(event);
                      }}
                    ></button>
                  </div>
                  <div className='qr-button-container'>
                    {Config.all_viewports.home.qr_button && <>
                      <ActionButton legend={QrIcon} handleClick={handleStartScan} />
                      <QrExplanation />
                    </>
                    }
                  </div>
                </div>

                {Config.all_viewports.home.qr_button && <>
                  <QrScanner
                    setIsQrOpen={setIsQrOpen}
                    startScan={startScan}
                    setStartScan={setStartScan}
                    setError={setError}
                    error={error}
                  />
                </>
                }

              </div>
            </Form.Group>
          </div>
          <div
            className={
              'input-error input-search' + (error !== '' ? ' error' : '')
            }
          >
            {error &&
              error.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
          </div>

          <div className={'home-go-down'}>
            {/* Disabled until we have Karun's indications */}
            {/*  <p className="no-code-look">
              {t('home.look')}
              <a onClick={() => setOpen(true)} className="no-code-look">
                {t('home.here')}
              </a>
            </p> */}
            <p
              className={`how-to-enter-code ${isMobile && isQrOpen ? 'hide' : ''
                } `}
            >
              <p className='how-to-enter-code-text' onClick={() => setOpenInstructions(true)}>{t('home.how')}</p>
            </p>
          </div>
          <div id="footer" className="karun-footer">
            <img
              className="footer-logo"
              src={bottomLogo}
              alt="Karun"
              width="200px"
            />
          </div>
        </div>
        {openInstructions && (
          <div className="overlay-background">
            <div className="home-instructions">
              <div className="home-steps" ref={stepsRef}>
                <button
                  className="home-instructions-closeButton"
                  onClick={() => setOpenInstructions(false)}
                >
                  X
                </button>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep1')}</h3>
                  <img src={step1} alt="step1" width="270px" />
                  <p>{t('home.step1')}</p>
                </div>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep2')}</h3>
                  <img src={step2} alt="step2" width="270px" />
                  <p>{t('home.step2')}</p>
                </div>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep3')}</h3>
                  <img src={step3} alt="step3" width="270px" />
                  <p>{t('home.step3')}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        show={showModal}
        onHide={handleClose}
        text={modalMessage}
        className={'route-alert-modal'}
      />
    </MainLayout>
  );
});

export default withTranslation()(Home);
