import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CloseButton from 'react-bootstrap/CloseButton';
import './styles.scss';
import AwsCheck from '../../atoms/AwsCheck';
import { useTranslation } from 'react-i18next';
import { Modal, Accordion, Row } from 'react-bootstrap';

const LensDetail = ({ lens, handleClose, blockchain }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const [showAudit, setShowAudit] = useState(false);

  const formatDate = React.useMemo(() => {
    return unformattedDate => {
      const date = new Date(unformattedDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      return date.toLocaleDateString('es-AR', options);
    };
  }, []);

  const handleShowAudit = () => setShowAudit(!showAudit);
  return (
    <div className="lens-detail">
      <CloseButton onClick={handleClose} />
      <Offcanvas.Body>
        <div className="items-lens">
          <div className="item-shoes-image">
            {lens && lens.url && <img src={lens.url} alt="lens" />}
          </div>

          <div className="item-description">
            <ul className="item-description-container">
              <li>
                {lens.material_one}:{' '}
                <span>
                {language === 'es' ?  lens.material_one_description_es
                      :  lens.material_one_description
                    }
                </span>
              </li>
                {lens.material_one !== lens.material_two && (
                  <li>
                    {lens.material_two}:{' '}
                    <span> 
                      {language === 'es' ?  lens.material_two_description_es
                      :  lens.material_two_description}
                    </span>
                  </li>
                )}
              {/* <li>
                {t('mapZeroCard.glassFiber')}:{' '}
                <span>
                  {language
                    ? lens?.[`recycled_materials_${language}`]
                    : lens?.recycled_materials || ''
                  }
                </span>
              </li> */}
            </ul>
          </div>

          <hr />

          <span className="traceability">
            {t('mapZeroCard.blockchainTraceability')}
          </span>

          <AwsCheck />
          <span className="detail-audit-link" onClick={handleShowAudit}>
            Audit Log
          </span>
        </div>
      </Offcanvas.Body>

      <Modal show={showAudit} onHide={handleShowAudit} centered>
        <Modal.Body className="modal-body-auditlog">
          <CloseButton onClick={handleShowAudit} />
          <h5>
            {t('mapZeroCard.productSku')}: {lens?.sku} - {lens?.batch}
          </h5>
          <Accordion>
            {blockchain &&
              blockchain.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.aws_id}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <h6>{t('mapZeroCard.event')}: </h6>
                      <p>
                        {' '}
                        {item.event_type.charAt(0).toUpperCase() +
                          item.event_type.slice(1)}
                      </p>
                    </Row>
                    <Row>
                      <h6>AWS ID: </h6>
                      <p>{item.aws_id}</p>
                    </Row>
                    <Row>
                      <h6>{t('mapZeroCard.creationDate')}: </h6>
                      <p>{formatDate(item.created_at)}</p>
                    </Row>
                    <Row>
                      <h6>{t('mapZeroCard.lastUpdate')}: </h6>
                      <p>{formatDate(item.updated_at)}</p>
                    </Row>
                    <Row>
                      <h6>{t('mapZeroCard.modelName')}</h6>
                      <p>{item.model_name}</p>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default React.memo(LensDetail);
