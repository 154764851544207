export const productFields = (data, product) => {
    const result = {
        lens: {
            posType: data.points_of_sale_screen_type.type,//
            url: data.fields["Front View"]?.[0].thumbnails.large.url,//
            name: data.fields["Name"],//
            name_trace: data.fields["Name"],//
            name_trace_es: data.fields["Name"],//
            co2: data.fields["CO2 Emission (kg)"],//
            co2_trace: null,
            bioequivalence: "Our glasses made from recycled materials have up to 90% lower carbon footprint compared to virgin materials.Based on Econyl, recycled nylon.",
            bioequivalence_es: "Nuestros anteojos reciclados reducen hasta un 90% la huella de carbono comparado con materiales vírgenes.Basado en Econyl, nylon reciclado.",

            material_one: data.fields["Frame Material"]?.[0],//
            material_one_es: null,
            material_one_description: data.fields["Trace: Frame Material  Description"],
            material_one_description_es: data.fields["Trace: Descripción Material Principal 1"],
            material_two: data.fields["Temple Material"]?.[0],//
            material_two_es: null,
            material_two_description: data.fields["Trace: Temple Material Description"],
            material_two_description_es: data.fields["Trace :Descripción Material Principal 2"],

            recycled_materials: null,
            recycled_materials_es: null,
            sku: data.sku,//
            batch: data.manufacturing_batch,//

            color: data.fields["Web Color - English"],
            color_es: data.fields["Web Color - Spanish"],
            type: data.fields["Eyewear Type"],
            material_description: data.fields["Description Frame Material "],
            material_description_es: data.fields["Descripción material del marco"],
        },
        boot: {
            posType: data.points_of_sale_screen_type.type,
            url: data.fields['Lateral Image']?.[0].thumbnails.large.url,
            name: data.fields['Name'],
            name_trace: data.fields['Name (Trace System)'],
            name_trace_es: data.fields['Nombre (Trace)'],
            co2: data.fields['C02 Production Emission (kg)'],
            co2_trace: data.fields['CO2 Tracer Emission'],
            bioequivalence: data.fields['Bioequivalence'],
            bioequivalence_es: data.fields['Bioequivalencia'],
            material_one: data.fields['Boot Material'],
            material_one_es: data.fields['Material Botín'],
            material_two: data.fields['Base Material'],
            material_two_es: data.fields['Material Base'],
            recycled_materials: data.fields['Card 0 - Recycled Materials'],
            recycled_materials_es: data.fields['Card 0 - Material Reciclado'],
            sku: data.sku,
            batch: data.manufacturing_batch,
        }
    };

    return result[product];

};