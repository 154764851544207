//BOOTS ASSETS
import BOOT_VIDEO_BG_DKT from "../assets/videos/boot/home_videobg_dkt.mp4";
import BOOT_VIDEO_BG_MOB from "../assets/videos/boot/home_videobg_mob.mp4";
import BOOT_TOP_DKT_LOGO from "../assets/images/join_planet_mobile.svg"
import BOOT_TOP_MOB_LOGO from "../assets/images/join_planet_mobile.svg"
import BOOT_BOTTOM_LOGO from "../assets/images/logo-kts.png"
import BOOT_HOME_MODAL_STEP1 from "../assets/images/boot/JTP_Step1.png";
import BOOT_HOME_MODAL_STEP2 from "../assets/images/boot/JTP_Step2.png";
import BOOT_HOME_MODAL_STEP3 from "../assets/images/boot/JTP_Step3.png";

//LENS ASSETS
import LENS_VIDEO_BG_DKT from "../assets/videos/lens/home_videobg_dkt.mp4";
import LENS_VIDEO_BG_MOB from "../assets/videos/lens/home_videobg_mob.mp4";
import LENS_TOP_DKT_LOGO from "../assets/images/logo-kts.png"
import LENS_TOP_MOB_LOGO from "../assets/images/logo-kts.png"
import LENS_BOTTOM_LOGO from "../assets/images/nest-logo.png"
import LENS_HOME_MODAL_STEP1 from "../assets/images/lens/step_1.gif";
import LENS_HOME_MODAL_STEP2 from "../assets/images/lens/step_2.gif";
import LENS_HOME_MODAL_STEP3 from "../assets/images/lens/step_3.gif";
import LENS_SEARCH_LOGO from "../assets/images/nest-logo.png";

export const Assets = {
    boot: {
        video_bg_dkt: BOOT_VIDEO_BG_DKT,
        video_bg_mob: BOOT_VIDEO_BG_MOB,
        home: {
            logos: {
                top_dkt: BOOT_TOP_DKT_LOGO,
                top_mobile: BOOT_TOP_MOB_LOGO,
                bottom: BOOT_BOTTOM_LOGO,
            },
            modal: {
                step1: BOOT_HOME_MODAL_STEP1,
                step2: BOOT_HOME_MODAL_STEP2,
                step3: BOOT_HOME_MODAL_STEP3,
            }
        },
        search: {
            isLogoActive: false,
            logo: '',
        }
    },
    lens: {
        video_bg_dkt: LENS_VIDEO_BG_DKT,
        video_bg_mob: LENS_VIDEO_BG_MOB,
        home: {
            logos: {
                top_dkt: LENS_TOP_DKT_LOGO,
                top_mobile: LENS_TOP_MOB_LOGO,
                bottom: LENS_BOTTOM_LOGO,
            },
            modal: {
                step1: LENS_HOME_MODAL_STEP1,
                step2: LENS_HOME_MODAL_STEP2,
                step3: LENS_HOME_MODAL_STEP3,
            }
        },
        search: {
            isLogoActive: true,
            logo: LENS_SEARCH_LOGO
        }
    }
};