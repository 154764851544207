import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HomeLensTemplate from '../../components/templates/HomeLens';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';
import { PointsOfSaleService } from 'services/PointsOfSaleService';
import { RouteService } from 'services/RouteService';

const HomeLens = props => {
  const history = useHistory();
  const [countriesList, setCountriesList] = useState([]);

  const [statesList, setStatesList] = useState([]);
  const [stateId, setStateId] = useState(null);

  const [citiesList, setCitiesList] = useState([]);
  const [cityId, setCityId] = useState(null);

  const [posList, setPosList] = useState([]);
  //const [posId, setPosId] = useState(null);

  const [{ lens, posId }, dispatchPersist] = useStorePersist();
  const { i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    localStorage.removeItem('routesData-en');
    localStorage.removeItem('routesData-es');
    localStorage.removeItem('routes-en');
    localStorage.removeItem('routes-es');


    if (lens.posType === 'T2') {
      PointsOfSaleService.T2.country(lens.batch)
        .then(data => {
          setCountriesList(data);
        })
        .catch(err => console.log(err));
    }

    if (lens.posType === 'T3') {
      PointsOfSaleService.T3.country()
        .then(data => {
          setCountriesList(data);
          dispatchPersist({
            type: 'setPosId',
            payload: null
          });
        })
        .catch(err => console.log(err));
    }
    dispatchPersist({
      type: 'setCity',
      payload: null
    });
  }, []);

  const dinamicCountrySelect = {
    options: countriesList.map(country => ({
      name: lens.posType === 'T2' ? country.name : (language ? country[`name_${language}`] : country.name),
      id_country: country.id,
      value: country.id
    }))
  };


  const handleGetStates = async countryId => {
    if (lens.posType === 'T2') {
      await PointsOfSaleService.T2.state(countryId, lens.batch)
        .then(data => {
          setStatesList(data);
        })
        .catch(err => console.log(err));
    }

    if (lens.posType === 'T3') {
      await PointsOfSaleService.T3.state(countryId)
        .then(data => {
          setStatesList(data);
        })
        .catch(err => console.log(err));
    }
  };

  const dinamicStateSelect = {
    options: statesList.map(state => ({
      name: lens.posType === 'T2' ? state.name : (language ? state[`name_${language}`] : state.name),
      id_state: state.id,
      value: state.id
    }))
  };


  const handleGetCities = async stateId => {
    if (lens.posType === 'T2') {
      await PointsOfSaleService.T2.city(stateId, lens.batch)
        .then(data => {
          setCitiesList(data);
        })
        .catch(err => console.log(err));
    }

    if (lens.posType === 'T3') {
      await PointsOfSaleService.T3.city(stateId)
        .then(data => {
          setCitiesList(data);
        })
        .catch(err => console.log(err));
    }
  };

  const dinamicCitySelect = {
    options: citiesList.map(city => ({
      name: lens.posType === 'T2' ? city.name : language ? city[`name_${language}`] : city.name,
      id_city: city.id,
      value: city.id
    }))
  };


  const handleGetPos = async (cityId) => {
    await PointsOfSaleService.T2.pos(cityId, lens.batch)
      .then(data => {
        setPosList(data);
      }).catch(err => console.log(err));

  };

  const dinamicPosSelect = {
    options: posList.map(pos => ({
      name: pos.name,
      id_pos: pos.id,
      value: pos.id
    }))
  };

  const setCity = aCity => {
    setError(null);
    dispatchPersist({
      type: 'setCity',
      payload: aCity
    });
    dispatchPersist({
      type: 'setCityStr',
      payload: aCity
    });
  };

  const setState = aState => {
    setError(null);
    dispatchPersist({
      type: 'setState',
      payload: aState
    });
  };

  const setCountry = (aCountry, aCountryStr) => {
    setError(null);
    dispatchPersist({
      type: 'setCountry',
      payload: aCountry
    });
    dispatchPersist({
      type: 'setCountryStr',
      payload: aCountryStr
    });
  };

  const setPos = posId => {
    setError(null);
    dispatchPersist({
      type: 'setPosId',
      payload: posId
    });
  };

  const handleClickIngresar = () => {
    setError(null);
    RouteService.check(null, null, null, posId)
      .then(response => {
        if (response.blocked) {
          setShowModal(true);
          const message = language ? response[`message_${language}`] : response.message;
          setModalMessage(message);
        } else {
          history.push({
            pathname: '/map'
          });
        }
      }

      )
      .catch(error => {
        console.log('error in route-check service: ', error);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };


  return (
    <HomeLensTemplate
      lens={lens}
      sku={lens && lens.sku}
      dinamicCountrySelect={dinamicCountrySelect}
      dinamicStateSelect={dinamicStateSelect}
      dinamicCitySelect={dinamicCitySelect}
      dinamicPosSelect={dinamicPosSelect}
      setPos={setPos}
      setCountry={setCountry}
      setState={setState}
      setCity={setCity}
      handleGetStates={handleGetStates}
      handleGetCities={handleGetCities}
      handleClickIngresar={handleClickIngresar}
      handleGetPos={handleGetPos}
      error={error}
      handleClose={handleClose}
      showModal={showModal}
      modalMessage={modalMessage}
    />
  );
};

export default withTranslation()(HomeLens);
