import React from 'react';
import { useStorePersist } from 'store/context';
import { closeTooltip } from './TooltipDialog';
import { useTranslation } from 'react-i18next';

const TooltipTopBar = () => {
  const [{ locale }, dispatchPersist] = useStorePersist();

  const { t } = useTranslation();

  return (
    <div id="tooltip-topbar">
      <p>{t('tooltip.tooltipTopbarMain')}</p>
      <button onClick={() => closeTooltip(dispatchPersist)}>
        {'>> '}
        {t('tooltip.tooltipTopbarClose')}
      </button>
    </div>
  );
};

export default TooltipTopBar;
