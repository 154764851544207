import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import Config from 'config/config';

import './styles.scss';


const LastCardMobile = ({ iconic }) => {
    const { t } = useTranslation();

    return (
        <div className="ĺast-card-container">
            <div className="card-iconic-image">
                <div className="iconic-image-container">
                    {iconic && <img src={iconic} alt="iconic image" />}
                </div>
            </div>
            <Card className="card-with-image">
                <Card.Body>
                    <div className="card-title-container">
                        <span>+</span>
                        <h2>{t('mapLastCard.karunSeeMore')}
                            <a href={Config.all_viewports.map.seeMoreCard.url} target='_blank'>{t('mapLastCard.here')}</a>
                        </h2>
                    </div>
                </Card.Body>

            </Card>

        </div>
    );
};

export default LastCardMobile;