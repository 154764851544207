export const rootWidth = document.querySelector('#root').offsetWidth;

export const isMobile = document.querySelector('#root').offsetWidth <= 768;

export const isNotebook =
  document.querySelector('#root').offsetWidth >= 1000 &&
  document.querySelector('#root').offsetWidth <= 1900;

export const isLaptopL =
  document.querySelector('#root').offsetHeight <= 855 &&
  document.querySelector('#root').offsetWidth >= 1000 &&
  document.querySelector('#root').offsetWidth <= 1900;
