import React, { useState } from 'react';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';

const TooltipSwitch = () => {
  const { t } = useTranslation();
  const [{ tooltipCompleted }, dispatchPersist] = useStorePersist();

  const [activeHighlight, setActiveHighlight] = useState(true);

  // Animacion de boton de ayuda recien cargado
  setTimeout(() => {
    setActiveHighlight(false);
  }, 6000);

  const dispatchTooltipCompleted = status => {
    const opaques = document.querySelectorAll('.opaque');
    if (opaques) {
      opaques.forEach(item => {
        item.classList.remove('opaque');
      });
    }

    dispatchPersist({
      type: 'setTooltipCompleted',
      payload: status
    });
  };

  return (
    <div
      id="boton-tooltip"
      className="toolkit-highlighted-component"
      title={t('tooltip.openTooltipButton')}
    >
      <button
        onClick={() => dispatchTooltipCompleted(!tooltipCompleted)}
        className={activeHighlight ? 'highlighted' : ''}
      >
        ?
      </button>
    </div>
  );
};

export default React.memo(TooltipSwitch)
