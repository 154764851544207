import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QrArrow } from '../../../assets/images/qr-arrow.svg';

import './styles.scss';

const QrExplanation = () => {
    const { t } = useTranslation();
    return (
        <div className='explanation-container'>
            <QrArrow />

            <h6>{t('home.qrExplanation')}</h6>
        </div>
    );
};

export default QrExplanation;