import Environment from '../environment';

import Api from './ApiService';

export const ProductService = {
  search: (sku, batch, qrCode) => {
    return new Promise(async (resolve, reject) => {
      if (qrCode) {
        try {
          const data = await Api.fetchNoToken(
            `${Environment.api}trace-products?code=${qrCode}`,
            'GET'
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      } else {
        try {
          const data = await Api.fetchNoToken(
            `${Environment.api}trace-products?sku=${sku}&batch=${batch}`,
            'GET'
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      }
    });
  }
};

// export const LensService = {
//   search: (sku, batch) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const data = await Api.fetchNoToken(
//           `${Environment.api}trace-products?sku=${sku}&batch=${batch}`,
//           'GET'
//         );
//         resolve(data);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   }
// };
