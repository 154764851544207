//BOOTS TRANSLATIONS
import BOOT_ES from '../translations/boot/es/common.json';
import BOOT_EN from '../translations/boot/en/common.json';

//LENS TRANSLATIONS
import LENS_ES from '../translations/lens/es/common.json';
import LENS_EN from '../translations/lens/en/common.json';

export const Translations = {
    boot: {
        es: {
            translation: BOOT_ES
        },
        en: {
            translation: BOOT_EN
        }
    },
    lens: {
        es: {
            translation: LENS_ES
        },
        en: {
            translation: LENS_EN
        }
    }
};