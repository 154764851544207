import React from 'react';
import ReactMapGl, { NavigationControl } from 'react-map-gl';
import { useStorePersist } from 'store/context';
import CustomMarker from 'components/atoms/CustomMarker';
import 'mapbox-gl/dist/mapbox-gl.css';
import Environment from '../../../environment';
import Config from 'config/config';
import './styles.scss';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapboxToken = Environment.mapbox_api_key;

const colorMap = Config.all_viewports.map.colorMap;
const mapBgGradient = Config.all_viewports.map.mapBgGradient;

const MapComponent = props => {
  const [{ isShortRoute }] = useStorePersist();
  const isActive = index => parseInt(props.routeIndex) === index;

  const memoizedColorMap = React.useMemo(() => {
    return {
      ...colorMap,
    };
  }, []);

  const memoizedMapboxToken = React.useMemo(() => {
    return mapboxToken;
  }, []);

  const memoizedMarkers = React.useMemo(() => {
    if (!props.markers) {
      return [];
    }

    return props.markers.flatMap((markersArrays, i) =>
      markersArrays.flatMap((marker, j) => {
        const points = marker.origin?.map((point, k) => (
          <CustomMarker
            latitude={point.latitude}
            longitude={point.longitude}
            isActive={isActive(i + 1)}
            key={`origin-${i}-${j}-${k}`}
            cardIndex={i + 1}
          />
        ));

        /* 
          T1:  Ocultar todo lo posterior a fabricación.
                if (es ruta corta) no mostrar lote Manufacturing
          T2:  Punto de venta en la ruta. Ruta completa. último punto destiny type: PointOfSale
                if(es ruta corta ) NO MOSTRAR DESTINY MANUFACTURING IDEM T1
          T3:  Listado de ciudades con HC, último punto destiny type: dELIVERYpLACE
                if(es ruta corta)  NO MOSTRAR DESTINY MANUFACTURING IDEM T1
        */
      
       if (!isShortRoute) {
        
            const destinyPoints = marker.destiny.map((point, k) => (
              <CustomMarker
                latitude={point.latitude}
                longitude={point.longitude}
                isActive={isActive(i + 2)}
                key={`destiny-${i}-${j}-${k}`}
                cardIndex={i + 2}
              />
            ));

            const listDestiniesPoints = destinyPoints.length > 1 ? [destinyPoints.slice(1)] : (i === props.markers.length - 1 ? [destinyPoints] : []);

            return [...points, ...listDestiniesPoints];
          }

        return points;
      })

    );
  }, [props.markers, isShortRoute, isActive]);

  return (
    <ReactMapGl
      {...props.viewport}
      onViewportChange={props.onViewportChange}
      dragPan={true}
      scrollZoom={false}
      mapboxApiAccessToken={memoizedMapboxToken}
      mapStyle={memoizedColorMap}
      attributionControl={false}
      style={mapBgGradient}
    >
      {memoizedMarkers}
      {props.children}
      <div id="navigationControls">
        <NavigationControl showCompass={false} />
      </div>
    </ReactMapGl>
  );
};

export default React.memo(MapComponent);
