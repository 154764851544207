import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import FormSelectB from 'react-bootstrap/FormSelect';
import { useStorePersist } from 'store/context';
import { isMobile } from 'helpers/Mobile';

const LanguageSwitch = ({ isTrace }) => {
  const { i18n } = useTranslation();
  const [{ locale }, dispatchPersist] = useStorePersist();

  const changeLanguageHandler = event => {
    i18n.changeLanguage(event.target.value);
    dispatchPersist({
      type: 'setLocale',
      payload: event.target.value
    });
  };
  return (
    <div className={'language-switch'}
      style={{
        zIndex: isTrace && isMobile ? '1' : '1000'
      }}
    >
      <FormSelectB onChange={changeLanguageHandler} value={i18n.language}>
        <option value="en" className={'select-option'}>
          {isMobile ? 'EN' : 'English'}
        </option>
        <option value="es" className={'select-option'}>
          {isMobile ? 'ES' : 'Español'}
        </option>
      </FormSelectB>
    </div>
  );
};

export default LanguageSwitch;
