import React from 'react';
import './styles.scss';
import { isMobile } from 'helpers/Mobile';

const LocationPin = props => {
  return (
    <div
      className={`${isMobile ? 'location-pin-mobile' : 'location-pin'}`}
      style={props.styles}
    >
      <span className="location-pin-numbers" style={props.numberStyles}>
        {props.cardIndex}
      </span>
    </div>
  );
};

export default LocationPin;
