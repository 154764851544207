import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Sidebar from '../Sidebar';
import './styles.scss';
import Config from 'config/config';

const CardLensDetail = ({ lens, country, blockchain, style, deliveryCarbonFootprint }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleShowOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { isDeliveryActive } = Config.all_viewports.map.carbonFootprint;

  return (
    <>
      <div className="card-img-container">
        <div className="lens-image-container">
          {lens && lens.url && <img src={lens.url} alt="lens" />}
        </div>
        <Card className="card-lens-master" style={style}>
          <div className="card-lens-container">
            <h2>
              {language ? lens[`name_trace_${language}`] : lens.name_trace}
            </h2>
            <ul>
              <li className="list-title">
                {t('mapAllCards.carbonFootprint')}

              </li>

              <li className="list-item">
                <h5>{t('mapAllCards.carbonFootprintProduction')}</h5>
                <span>{lens.co2}kg CO2e</span>
              </li>

              <li className="list-item">
                <h5>{t('mapAllCards.carbonFootprintTracer')} <span onClick={handleShowModal} className='info-carbon-footprint-mobile'> i</span></h5>
                <span>{lens.co2_trace} 1.55kg C02e</span>
              </li>
              {isDeliveryActive &&
                (lens.posType === 'T3') &&
                <li className="list-item">
                  <h5>{t('mapAllCards.carbonFootprintDelivery')}</h5>
                  <span>{deliveryCarbonFootprint} kg CO2e</span>
                </li>
              }


              <li className="list-item">
                <p>  {(language
                  ? lens?.[`bioequivalence_${language}`]
                  : lens?.bioequivalence || ''
                )?.length > 140
                  ? (language
                    ? lens?.[`bioequivalence_${language}`]
                    : lens?.bioequivalence || ''
                  ).slice(0, 140) + '...'
                  : language
                    ? lens?.[`bioequivalence_${language}`]
                    : lens?.bioequivalence || ''}</p>
              </li>
            </ul>
            <span
              className="card-with-image-line"
              onClick={() => handleShowOffcanvas()}
            >
              {t('mapAllCards.seeMore')}
            </span>
          </div>
        </Card>
      </div>
      {/* Postponed */}
      <div style={{ display: 'none' }}>
        <Sidebar
          showMenu={false}
          showOffcanvas={showOffcanvas}
          closeOffcanvas={handleShowOffcanvas}
          lens={lens}
          country={country}
          blockchain={blockchain}
        />
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="mobile-info-footprint-modal justify-content-center"
        centered
      >
        <Modal.Header closeButton>
          <h5>{t('mapZeroCard.tracerCarbonFootprint')}</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('mapZeroCard.carbonFootprintInfo')}
          </p>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardLensDetail;
