import React, { useState } from 'react';
import Logo from 'assets/images/karun-logo.png';
import LogoTrace from 'assets/images/logo-kts.png';
import { Container, Navbar, CloseButton } from 'react-bootstrap';
import LensDetail from '../LensDetail';
import LanguageSwitch from '../../atoms/LanguageSwitch';
import { isMobile } from 'helpers/Mobile';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

const Sidebar = ({
  lens,
  showMenu = false,
  handleClose,
  showOffcanvas,
  closeOffcanvas,
  blockchain,
  isTrace,
  isHome
}) => {
  return (
    <div className="sidebar-container">
      <Navbar expand={false} variant="dark">
        <Container fluid className={`${isTrace ? 'traceNav' : ''}`}>
          {showMenu && (
            <Navbar.Toggle aria-controls="offcanvasNavbar" variant="light" />
          )}

          <LanguageSwitch isTrace={isTrace} />

          <Navbar.Brand
            href="https://www.karunworld.com/jointheplanet"
            className={`${isHome ? 'navbar-brand-mobile' : ''}`}
            style={{ "zIndex": isHome ? "1000" : '1' }}
            target='_blank'
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: isMobile ? '' : '108px',
                marginLeft: isMobile ? '' : '3%'
              }}
            />
          </Navbar.Brand>

          {isTrace && <CloseButton variant="white" onClick={handleClose} />}

          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={closeOffcanvas}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            scroll="false"
            backdrop="false"
          >
            {lens ? (
              <LensDetail
                lens={lens}
                handleClose={closeOffcanvas}
                canClose={true}
                blockchain={blockchain}
              />
            ) : (
              <></>
            )}
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

export default Sidebar;
