import React, { useMemo } from 'react';
import { Modal, Accordion, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AuditLogModal = ({ showAudit, handleCloseAudit, lens, blockchain }) => {
  const { t } = useTranslation();

  // Formateo de fecha para AuditLog
  const formatDate = useMemo(() => {
    return UnformattedDate => {
      const date = new Date(UnformattedDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      return date.toLocaleDateString('es-AR', options);
    }
  }, [])

  const blockchainItems = React.useMemo(() => {
    return blockchain?.map((item, index) => (
      <Accordion.Item eventKey={index} key={index}>
        <Accordion.Header>{item.aws_id}</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col centered>
              <h6>{t('mapZeroCard.event')}: </h6>
              <p>
                {' '}
                {item.event_type.charAt(0).toUpperCase() +
                  item.event_type.slice(1)}
              </p>
            </Col>
            <Col centered>
              <h6>AWS ID: </h6>
              <p>{item.aws_id}</p>
            </Col>
            <Col centered>
              <h6>{t('mapZeroCard.creationDate')}: </h6>
              <p>{formatDate(item.created_at)}</p>
            </Col>
            <Col centered>
              <h6>{t('mapZeroCard.lastUpdate')}: </h6>
              <p>{formatDate(item.updated_at)}</p>
            </Col>
            <Col centered>
              <h6>{t('mapZeroCard.modelName')}</h6>
              <p>{item.model_name}</p>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    ))
  }, [blockchain])

  return (
    <>
      {/* Audit Log Modal */}
      <Modal
        show={showAudit}
        onHide={handleCloseAudit}
        className="audit-log-modal-dkt"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="audit-log-closebtn" />
        <Modal.Body>
          <h5 className="audit-log-title">AUDIT LOG</h5>
          <div className="audit-log-header">
            <h5 className="audit-log-sku-title">
              {t('mapZeroCard.productSku')}:
            </h5>{' '}
            <h5 className="audit-log-sku-batch">
              {lens?.sku} - {lens?.batch}
            </h5>
          </div>
          <Accordion>
            {blockchainItems}
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(AuditLogModal)
