import React, { useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import MainLayout from 'components/organisms/MainLayout';
import ActionButton from 'components/atoms/ActionButton';
import FormSelect from '../../atoms/FormSelect';
import { isMobile } from 'helpers/Mobile';
import Config from '../../../config/config.js';
import './styles.scss';
import CustomModal from 'components/molecules/CustomModal';

const logo = Config.assets[Config.product_theme].search.logo;
const isLogoActive = Config.assets[Config.product_theme].search.isLogoActive;
const video = Config.assets[Config.product_theme].video_bg_dkt;

const HomeLensTemplate = React.memo(
  ({
    lens,
    sku = '',
    dinamicCountrySelect,
    dinamicStateSelect,
    dinamicCitySelect,
    setPos,
    setCountry,
    setState,
    setCity,
    handleGetStates,
    handleGetCities,
    handleClickIngresar,
    error,
    handleGetPos,
    dinamicPosSelect,
    handleClose,
    showModal,
    modalMessage
  }) => {
    const { t } = useTranslation();
    const [activeSelect, setActiveSelect] = useState(0);

    const handleCountryCity = e => {
      setCountry(e.target.value, e.target[e.target.selectedIndex].text);

      const selectedCountryObj = dinamicCountrySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      setCity(selectedCountryObj.city);
      setPos(selectedCountryObj.posId);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePopulateStates = e => {
      const selectedCountryObj = dinamicCountrySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      handleGetStates(selectedCountryObj.id_country);
      setCountry(selectedCountryObj.id_country);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePopulateCities = e => {
      const selectedStateObj = dinamicStateSelect.options.find(
        item => item.value.toString() === e.target.value
      );
      handleGetCities(selectedStateObj.id_state);
      setState(selectedStateObj.id_state);
      setActiveSelect(prevState => prevState + 1);
    };

    const handleCity = e => {
      const selectedCityObj = dinamicCitySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      setCity(selectedCityObj.id_city);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePopulatePos = e => {
      const selectedCityObj = dinamicCitySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      handleGetPos(selectedCityObj.id_city);
      setCity(selectedCityObj.id_city);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePos = e => {
      const selectedCityObj = dinamicPosSelect.options.find(
        item => item.value.toString() === e.target.value
      );
      setPos(selectedCityObj.id_pos);
      setActiveSelect(prevState => prevState + 1);
    };


    const handleFinalSelect = e => {
      if (lens.posType === 'T3') {
        handleCity(e);
      } else if (lens.posType === 'T2') {
        handlePopulatePos(e);
      }
    };

    const disabledButton =
      (lens.posType === 'T2' && activeSelect !== 4) ||
      (lens.posType === 'T3' && activeSelect !== 3);

    return (
      <MainLayout showMenu={false} isSearch={true}>
        <div className="lens-container">
          {!isMobile && (
            <div className="video-background-container-dkt">
              <video autoPlay loop muted playsInline>
                <source src={video} />
              </video>
            </div>
          )}
          <div className="lens-content">
            <div className="lens-data">
              {/* {isMobile ? (
              <JoinPlanetMobile className="joinPlanetMobile" />
            ) : (
              <JoinPlanetDkt className="joinPlanetDkt" />
            )} */}
              {lens && (
                <img
                  className={'lens-image ' + sku}
                  src={lens.url}
                  alt={lens.name}
                />
              )}

              <div className="selects-container">
                <FormSelect
                  options={dinamicCountrySelect.options}
                  label={t('search.countryLabel')}
                  ariaLabel={'aria-label-countries'}
                  onChange={e => handlePopulateStates(e)}
                  placeHolder={t('search.countryPlaceHolder')}
                />

                {
                  dinamicStateSelect.options.length > 0 && (
                    <FormSelect
                      options={dinamicStateSelect.options}
                      ariaLabel={'aria-label-countries'}
                      onChange={e => handlePopulateCities(e)}
                      placeHolder={t('search.statePlaceHolder')}
                    />
                  )}

                {dinamicCitySelect.options.length > 0 && (
                  <FormSelect
                    options={dinamicCitySelect.options}
                    ariaLabel={'aria-label-countries'}
                    onChange={e => handleFinalSelect(e)}
                    placeHolder={t('search.cityPlaceHolder')}
                  />
                )}

                {
                  dinamicPosSelect.options.length > 0 && (
                    <FormSelect
                      options={dinamicPosSelect.options}
                      ariaLabel={'aria-label-countries'}
                      onChange={e => handlePos(e)}
                      placeHolder={t('search.posPlaceHolder')}
                    />
                  )}
                <p
                  className="select-all-fields"
                  style={{ display: disabledButton ? 'block' : 'none', textAlign: 'center' }}
                >
                  {t('search.selectAllFields')}
                </p>
              </div>

            </div>
            <div className="lens-data last-element">
              {error && (
                <div className={'input-error '}>
                  <p>{error}</p>
                </div>
              )}
              <ActionButton
                className="action-button-homelens"
                buttonText={t('search.submit')}
                handleClick={handleClickIngresar}
                disabled={disabledButton}
              />
              <div className="back-button">
                <a href="/">
                  <div></div>
                  <p>{t('search.back')}</p>
                </a>
              </div>
              {isLogoActive &&
                <div>
                  <img src={logo} alt='logo' width='180px' />
                </div>
              }
            </div>
          </div>
        </div>
        <CustomModal
          show={showModal}
          onHide={handleClose}
          text={modalMessage}
          className={'route-alert-modal'}
        />
      </MainLayout>
    );
  }
);

export default withTranslation()(HomeLensTemplate);
