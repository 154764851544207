import Modal from 'react-bootstrap/Modal';

import './styles.scss';

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    //closeVariant='white'
    >
      <Modal.Header
        closeButton
      //  closeVariant='white'
      />
      <Modal.Body>
        <p>{props.text}</p>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;